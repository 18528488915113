import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cornell',
  templateUrl: './cornell.component.html',
  styleUrls: ['./cornell.component.css']
})
export class CornellComponent implements OnInit {

  constructor(public route:ActivatedRoute,public http:HttpClient,public spinner :NgxSpinnerService) { }
public cornellUtility:any ={
  totalData:{},
  date:new Date(),
  allUrls:[
    {
          url:"https://dev-live-classes.s3.ap-south-1.amazonaws.com/class-media/643e686cdf5ec003bd62b26e/bc760a759f4b37404aaf2fa896ae1a64_62682e43d4b2db035f5eae13_0 (online-video-cutter.com).mp4",
          topic:'Synthetic Fibre',
          subject :'Botany',
          teacher:'Swati Krishnan'
        },
        {
          url:"https://dev-live-classes.s3.ap-south-1.amazonaws.com/class-media/643e686cdf5ec003bd62b26e/2b9eb1d53d48101247de7e9ae1a56491-62eeb1cf7c75cc036b71deea-0_InCkTa6e.mp4",
          topic:'Getting to know Plants 1',
          subject :'Botany',
          teacher:'Divya Mehta'
        }
     ]
    }
  ngOnInit(): void {
 console.log( this.route.snapshot.queryParamMap.get('url')) 
 let url = this.route.snapshot.queryParamMap.get('url');
 if(url){
  this.spinner.show()
  const endpoint = 'https://newasmstageapi.devinfinitylearn.in/whatsapp/transcribeMediaUrl'
  this.http.post(endpoint,{url:url}).subscribe((resp:any)=>{
  let urldetails= this.cornellUtility.allUrls.find(v=>v.url == url)
  resp = {...resp,...urldetails}
    console.log(resp)
  this.spinner.hide()
    this.cornellUtility.totalData = resp;
  })
 }
  }

}
