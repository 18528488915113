import { environment } from "src/environments/environment"

export const states = [
    {
      "abbreviation": "35",
      "name": "Andaman and Nicobar Islands"
    },
    {
      "abbreviation": "37",
      "name": "Andhra Pradesh"
    },
    {
      "abbreviation": "12",
      "name": "Arunachal Pradesh"
    },
    {
      "abbreviation": "18",
      "name": "Assam"
    },
    {
      "abbreviation": "10",
      "name": "Bihar"
    },
    {
      "abbreviation": "4",
      "name": "Chandigarh"
    },
    {
      "abbreviation": "22",
      "name": "Chattisgarh"
    },
    {
      "abbreviation": "25",
      "name": "Dadra and Nagar Haveli"
    },
    {
      "abbreviation": "DD",
      "name": "Daman and Diu"
    },
    {
      "abbreviation": "7",
      "name": "Delhi"
    },
    {
      "abbreviation": "30",
      "name": "Goa"
    },
    {
      "abbreviation": "24",
      "name": "Gujarat"
    },
    {
      "abbreviation": "6",
      "name": "Haryana"
    },
    {
      "abbreviation": "2",
      "name": "Himachal Pradesh"
    },
    {
      "abbreviation": "1",
      "name": "Jammu and Kashmir"
    },
    {
      "abbreviation": "20",
      "name": "Jharkhand"
    },
    {
      "abbreviation": "29",
      "name": "Karnataka"
    },
    {
      "abbreviation": "32",
      "name": "Kerala"
    },
    {
      "abbreviation": "31",
      "name": "Lakshadweep"
    },
    {
      "abbreviation": "23",
      "name": "Madhya Pradesh"
    },
    {
      "abbreviation": "27",
      "name": "Maharashtra"
    },
    {
      "abbreviation": "14",
      "name": "Manipur"
    },
    {
      "abbreviation": "17",
      "name": "Meghalaya"
    },
    {
      "abbreviation": "15",
      "name": "Mizoram"
    },
    {
      "abbreviation": "13",
      "name": "Nagaland"
    },
    {
      "abbreviation": "21",
      "name": "Odisha"
    },
    {
      "abbreviation": "34",
      "name": "Puducherry"
    },
    {
      "abbreviation": "3",
      "name": "Punjab"
    },
    {
      "abbreviation": "8",
      "name": "Rajasthan"
    },
    {
      "abbreviation": "11",
      "name": "Sikkim"
    },
    {
      "abbreviation": "33",
      "name": "Tamil Nadu"
    },
    {
      "abbreviation": "36",
      "name": "Telangana"
    },
    {
      "abbreviation": "16",
      "name": "Tripura"
    },
    {
      "abbreviation": "9",
      "name": "Uttar Pradesh"
    },
    {
      "abbreviation": "5",
      "name": "Uttarakhand"
    },
    {
      "abbreviation": "19",
      "name": "West Bengal"
    }
  ]

export const months = ["January","February","March","April","May","June","July","August","September","October","November","December"]
  export const unifiedWeb = environment.unifiedweb;
  export const studentportal = environment.studentportal;