import { Component, OnInit } from '@angular/core';
import { MetatagsService } from 'src/app/service/metatags.service';
import { DatalayerService } from 'src/app/service/datalayer.service';
import { Title, Meta } from '@angular/platform-browser';
import { studentportal } from 'src/app/service/app.constant';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
declare let $: any;
@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})

export class MainpageComponent implements OnInit {
  studentportal = studentportal;
  isFromBajajFinservAppInApp: boolean = false;
  lazyloading:boolean = false;
  lazyliveclass:boolean = false;
  lazynews:boolean = false;
  lazyresults:boolean = false;
  lazyfooter:boolean = false;
  deviceWidth = window.innerWidth;
  metaData:any;
  selectedIndex = 0;
  isMigrationShow = environment.isMigrationShow;

  masterclassss = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/masterclassss.webp";
  doubtssolvess = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/doubtssolvess.webp";
  assessmentssss = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/assessmentssss.webp";
  hmanytime = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/hmanytime.png";
  Doubts = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/Doubts.webp";
  doubt = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/doubt.png";
  nwhm1 = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/nwhm1.png";
  nwhm2 = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/nwhm2.png";
  nwhm3 = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/nwhm3.png";
  nwhm4 = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/nwhm4.png";
  nwhm5 = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/nwhm5.png";
  nwhm6 = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/nwhm6.png";
  nwhm7 = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/nwhm7.png";
  nwhm8 = "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/nwhm8.png";

  results = {
    "rushil": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2021/neet2021/rushil.png",
    "shashank": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2021/neet2021/shashank.png",
    "suyash": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2021/neet2021/suyash.png",
    "paneesh": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/paneesh.png",
    "lokesh": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/lokesh.png",
    "guramrit": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/guramrit.png",
    "mridul": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2021/jeeadv2021/mridul.png",
    "chaitanya": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2021/jeeadv2021/chaitanya.png",
    "hrushikesh": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2021/jeeadv2021/hrushikesh.png",
  }
  news = {
    "wisklub": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/homenews/wisklub.webp",
    "rohitgyaanchand": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/homenews/rohitgyaanchand.webp",
    "hmedtech": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/homenews/hmedtech.webp",
    "hmprestige": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/homenews/hmprestige.webp",
    "news": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/homenews/news.webp",
    "newsrohit": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/homenews/news-rohit.webp",
    "newsteacher": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/homenews/news-teacher.webp",
    "newsdontmemorise": "https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/homenews/newsdontmemorise.webp",
  }
  constructor(
    private _services: MetatagsService,
    private _datalayer: DatalayerService,
    private titleService: Title,
    private metaService: Meta,
    private activatedRoute: ActivatedRoute) { }


  banner: any = [];

  ngOnInit(): void {
    let _params = { ...(this.activatedRoute.snapshot.queryParams || {}) };
    if (_params?.utm_source == "BajajFinserv") {
      sessionStorage.setItem("isFromBajajFinservAppInApp", "true");
      this.isFromBajajFinservAppInApp = true;
    }

    this._services.meta().subscribe((data) => {
      // this.titleService.setTitle('Infinity Learn - Add Success to Your Preparation on CBSE, IIT-JEE, NEET and ICSE.');
      // this.metaService.updateTag({ name: 'title', content: 'Infinity Learn - Add Success to Your Preparation on CBSE, IIT-JEE, NEET and ICSE.' }, "name='title'");
      // this.metaService.updateTag({ name: 'keywords', content: 'cbse, cbse class 12, cbse class 12, cbse class 12 board exams, cbse class 10 board exams, cbse board exams, cbse board, cbse class 10, neet prep, 12 board exam, class 12 board exam, neet preparation, jee preparation, iit preparation, jee main preparation' }, "name='keywords'");
      // this.metaService.updateTag({ name: 'description', content: 'Prepare for JEE Main, NEET, JEE Advanced, and CBSE school and board exams with ease . Get taught by the expert faculty and take your preparation to next level.' }, "name='description'");
    });

    this._services.metatag("/home").subscribe(res=>{
      console.log(res,'meta data');
      this.metaData = res[0]; 
      this.titleService.setTitle(res[0].meta_name);
      this.metaService.updateTag({ name: 'title', content: res[0].meta_name }, "name='title'");
      this.metaService.updateTag({ name: 'keywords', content: res[0].meta_keyword}, "name='keywords'");
      this.metaService.updateTag({ name: 'description', content: res[0].meta_description }, "name='description'");
    })

    this._services.banner('/home').subscribe((data) => {
      this.banner = data;
    })
    console.log(window.scrollY)
    if(this.deviceWidth<=481){
      setTimeout(()=>{
        this.lazyliveclass = true;
      },3000);
      setTimeout(()=>{
        this.lazyresults = true;
      },7000);
      setTimeout(()=>{
        this.lazynews = true;
      },10000);
      setTimeout(()=>{
        this.lazyfooter = true;
      },13000);
    } else{
      this.lazyliveclass = true;
      this.lazyresults = true;
      this.lazynews = true;
      this.lazyfooter = true;
    }

    this.lazyloading = true;
  }

  we_joinforfree() {
    this._datalayer.webEngageEvent({
      'event': 'CTA - Join for free',
      'page_url': window.location.href,
    })
  };

  we_register() {
    this._datalayer.webEngageEvent({
      'event': 'register_score_home',
      'page_url': window.location.href,
    })
  };

  we_download_learn_app() {
    this._datalayer.webEngageEvent({
      'event': 'download_learn_app',
      'page_url': window.location.href
    })
  };

  selectIndex(i){
    this.selectedIndex = i;
  }

}
