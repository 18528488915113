<header class="header">
  <nav class="navbar navbar-expand-lg home-navbar">
    <div class="hmnavflex">
      <div>
        <a class="navbar-brand" href="#">
          <img width="100%" height="100%" loading=""
            srcset="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/home-logo.webp"
            alt="Logo" class="headerlogo" />
        </a>
      </div>
      <app-header-signup></app-header-signup>
    </div>
    <div id="navbarSupportedContent" class="collapse navbar-collapse">
      <app-header></app-header>
    </div>
    <div id="mySidenav" class="sidenav sidebar">
      <a href="javascript:void(0)" onclick="closeNav()" class="closebtn">×</a>
      <app-header-mob></app-header-mob>
    </div>
    <span onclick="openNav()" class="leftnav">☰ </span>
  </nav>
</header>

<!-- ===========================Alert====================== -->
<div *ngIf="!isMigrationShow">
  <app-alert></app-alert>
</div>
<div class="ilalert" *ngIf="isMigrationShow">
  <div class="alert alert-server text-center cuetserver migration" role="alert">
    <button type="button" class="close" data-dismiss="alert">×</button>
    <p class="mb-0 alertmsg">Our systems are under migration between 11:00 PM - 12:00 AM</p>
  </div>
</div>


<div style="display: none;">
  <p>{{metaData?.meta_name}}</p>
  <p>{{metaData?.meta_keyword}}</p>
  <p>{{metaData?.meta_description}}</p>
</div>

<div class="">
  <section class="hero-area">
    <div class="container-fluid webowlnopad">
      <div id="webcarouselIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators homewebdots">
          <li data-target="#webcarouselIndicators" [attr.data-slide-to]="i" *ngFor="let d of banner; let i = index"
          [ngClass]="{active: (i == 0)}"></li>
        </ol>

        <div class="carousel-inner">
          <div class="carousel-item" *ngFor="let d of banner;let j = index" [ngClass]="{active: (j == 0)}">
            <a href="{{ d.url }}" target="_blank">
              <img width="100%" height="100%" class="webban" alt="" src="{{ d.webbanner }}" loading="lazy" /></a>
          </div>
        </div>

        <a class="carousel-control-prev hm_topcarouselprev" href="#webcarouselIndicators" role="button"
          data-slide="prev">
          <i class="fa fa-angle-left hm_topleftarrow" aria-hidden="true"></i>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next hm_topcarouselnext" href="#webcarouselIndicators" role="button"
          data-slide="next">
          <i class="fa fa-angle-right hm_toprightarrow" aria-hidden="true"></i>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>



    <div class="container-fluid mobileowlnopad">
      <div id="mobilecarouselIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators homemobdots">
          <li data-target="#mobilecarouselIndicators" [attr.data-slide-to]="j" *ngFor="let d of banner;let j = index"
            [ngClass]="{active: (j == 0)}"></li>
        </ol>

        <div class="carousel-inner">
          <div class="carousel-item" *ngFor="let d of banner;let j = index" [ngClass]="{active: (j == 0)}">
            <a href="{{ d.url }}" target="_blank">
              <img width="100%" height="100%" class="webban" alt="" src="{{ d.mobbanner }}" loading="lazy" /></a>
          </div>
        </div>

        <a class="carousel-control-prev hm_topcarouselprev" href="#mobilecarouselIndicators" role="button"
          data-slide="prev">
          <i class="fa fa-angle-left hm_topleftarrow" aria-hidden="true"></i>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next hm_topcarouselnext" href="#mobilecarouselIndicators" role="button"
          data-slide="next">
          <i class="fa fa-angle-right hm_toprightarrow" aria-hidden="true"></i>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </section>
  <!-- Meet our NEET superstars sec starts -->
  <section class="hmstudents_sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="hmstud_box">
            <div class="hmstuleft_text">
              <h4 class="hmstu_title">Meet our NEET superstars</h4>
              <p class="hmstu_txt">Watch our NEET 2022 toppers being candid as they discuss what it takes to be a NEET
                Champion.</p>
            </div>
            <div class="hmsturt_video">
              <a href="https://www.youtube.com/watch?v=e1eTLW_enew&ab_channel=InfinityLearn" target="_blank">
                <figure class="mb-0">
                  <img width="100%" height="100%" loading="lazy"
                    src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/hmebetteronline.webp"
                    alt="Angular" class="hmebetteronline">
                </figure>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Meet our NEET superstars sec ends -->
  <!-- Master Class sec starts  -->
  <section class="masterclass-area">
    <div class="container-fluid">
      <div class="row align-items-center masterflex">
        <div class="col-lg-6 col-md-12 col-xs-12 no-pad">
          <div class="masterclass-img">
            <figure>
              <img width="100%" height="100%" [src]="masterclassss" alt="" class="mastermainimg"
                [lazyLoad]="masterclassss" loading="lazy" />
            </figure>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-xs-12">
          <div class="masterclass-content">
            <h4 class="masterhead">Infinity Learn Masterclass</h4>
            <p class="mastersubhead">Experience masterclass from top faculty and make your learning fun and infinite</p>
            <div class="masterclass-items">
              <div class="masterlist">
                <div class="masterimg">
                  <figure class="mb-0">
                    <img width="100%" height="100%" [src]="nwhm1" alt="" class="titleimg" [lazyLoad]="nwhm1"
                      loading="lazy" />
                  </figure>
                </div>
                <div class="masterone-content">
                  <h5 class="titlehead">Learn at your own pace</h5>
                  <p class="titletext">
                    Get access to live and recorded classes that can be accessed
                    from any device
                  </p>
                </div>
              </div>
              <div class="masterlist">
                <div class="masterimg">
                  <figure class="mb-0">
                    <img width="100%" height="100%" [src]="nwhm2" alt="" class="titleimg" [lazyLoad]="nwhm2"
                      loading="lazy" />
                  </figure>
                </div>
                <div class="masterone-content">
                  <h5 class="titlehead">Fun & Interactive Live Lessons</h5>
                  <p class="titletext">
                    Get gamified experience with courses created by our experts
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Master Class sec ends  -->
  <!-- Test Preparation sec starts  -->
  <section class="testpreparation-area">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 col-xs-12">
          <div class="masterclass-content">
            <h4 class="masterhead">Take your Test Preparation to the next level</h4>
            <p class="mastersubhead">Ace your tests to build confidence for your exams</p>
            <div class="masterclass-items">
              <div class="masterlist">
                <div class="masterimg">
                  <figure class="mb-0">
                    <img width="100%" height="100%" [src]="nwhm3" alt="" class="titleimg" [lazyLoad]="nwhm3" />
                  </figure>
                </div>
                <div class="masterone-content">
                  <h5 class="titlehead">Previous Years Papers & Mock Tests</h5>
                  <p class="titletext">Revise and prepare with previous years papers & free Mock Tests for assured
                    success</p>
                </div>
              </div>
              <div class="masterlist">
                <div class="masterimg">
                  <figure class="mb-0">
                    <img width="100%" height="100%" [src]="nwhm4" alt="" class="titleimg" [lazyLoad]="nwhm4"
                      loading="lazy" />
                  </figure>
                </div>
                <div class="masterone-content">
                  <h5 class="titlehead">Detailed Reports</h5>
                  <p class="titletext">Get detailed report and analysis to improve your learning</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-xs-12 no-pad">
          <div class="masterclass-img">
            <figure class="mb-0">
              <img width="100%" height="100%" [src]="assessmentssss" rel="preload" alt="" class="testpreparationimg"
                [lazyLoad]="assessmentssss" loading="lazy" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Test Preparation sec ends  -->
  <div class="container" *ngIf="!lazyliveclass">
    <div class="row">
      <div class="col-md-12">
        <div class="loadcenter">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Learning at your own pace sec starts  -->
  <section class="masterclass-area">
    <div class="container-fluid">
      <div class="row align-items-center masterflex">
        <div class="col-lg-6 col-md-12 col-xs-12 no-pad">
          <div class="masterclass-img">
            <figure>
              <img [src]="hmanytime" rel="preload" alt="" class="mastermainimg" [lazyLoad]="hmanytime" loading="lazy" />
            </figure>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-xs-12">
          <div class="masterclass-content">
            <h4 class="masterhead">Learning at your own pace anytime, anywhere</h4>
            <p class="mastersubhead">
              An experience that’s a class apart by highly qualified faculty
              from Sri Chaitanya
            </p>
            <div class="masterclass-items">
              <div class="masterlist">
                <div class="masterimg">
                  <figure class="mb-0">
                    <img [src]="nwhm5" rel="preload" alt="" class="titleimg" [lazyLoad]="nwhm5" loading="lazy" />
                  </figure>
                </div>
                <div class="masterone-content">
                  <h5 class="titlehead">Learn at Your Own Pace</h5>
                  <p class="titletext">Engaging videos with real-life applications</p>
                </div>
              </div>
              <div class="masterlist">
                <div class="masterimg">
                  <figure class="mb-0">
                    <img [src]="nwhm6" rel="preload" alt="" class="titleimg" [lazyLoad]="nwhm6" loading="lazy" />
                  </figure>
                </div>
                <div class="masterone-content">
                  <h5 class="titlehead">Flashcard</h5>
                  <p class="titletext">Flashcards with animations & examples
                    for quick revision</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Learning at your own pace sec ends  -->
  <!-- ========================= Best Solutions starts ========================= -->
  <section class="masterclass-area">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12 col-xs-12">
          <div class="masterclass-content">
            <h4 class="masterhead">Solve your Doubts 24/7 instantly with just a tap</h4>
            <p class="mastersubhead">Prepping for JEE, NEET just got easier. Resolve every doubt with just one app
              absolutely free</p>
            <div class="masterclass-items">
              <div class="masterlist">
                <div class="masterimg">
                  <figure class="mb-0">
                    <img [src]="nwhm7" rel="preload" alt="" class="titleimg" [lazyLoad]="nwhm7" loading="lazy" />
                  </figure>
                </div>
                <div class="masterone-content">
                  <h5 class="titlehead">Solutions available round the clock</h5>
                  <p class="titletext">Your expert guide to solving doubts which provides solutions round the clock,
                    anytime, anywhere
                  </p>
                </div>
              </div>
              <div class="masterlist">
                <div class="masterimg">
                  <figure class="mb-0">
                    <img [src]="nwhm8" rel="preload" alt="" class="titleimg" [lazyLoad]="nwhm8" loading="lazy" />
                  </figure>
                </div>
                <div class="masterone-content">
                  <h5 class="titlehead">Step by Step Explanations</h5>
                  <p class="titletext">It solves all your doubts with a vast repository of step-by-step explanations for
                    every subject
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-xs-12 no-pad">
          <div class="masterclass-img">
            <figure>
              <img [src]="doubtssolvess" rel="preload" alt="" class="testpreparationimg" [lazyLoad]="doubtssolvess"
                loading="lazy" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ========================= Best Solutions ends ========================= -->



  <!-- <app-liveclass *ngIf="lazyliveclass"></app-liveclass> -->
  <app-faculty *ngIf="lazyliveclass"></app-faculty>



  <!-- ========================= Infinity Learn Experience ========================= -->
  <div class="container" *ngIf="!lazyresults && lazyliveclass">
    <div class="row">
      <div class="col-md-12">
        <div class="loadcenter">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </div>

  <app-testimonial *ngIf="lazyresults"></app-testimonial>


  <!-- ========================= Our Results starts ========================= -->

  <section class="ourresults-area" *ngIf="lazyresults">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-head">
            <h1 class="sec-title">Our Results Speak for Us</h1>
            <p class="sec-text">
              No progress ? Find a smarter way and be one of the thousands who
              succeeded with us!
            </p>
          </div>
        </div>
        <!-- JEE Main 2023-->
        <div class="col-md-12">
          <div class="resultbg">
            <div class="row jeeresult-flex">
              <div class="col-lg-5 col-xs-12 jeetopper-flex">
                <h3 class="topperhead-2021">
                  Congratulations to Our JEE Main Toppers 2023
                </h3>
              </div>
              <div class="col-lg-7 col-xs-12">
                <div class="topper">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/results/jeemains2023/koundinya.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 1</span>
                        <h3 class="toppername">Venkat Koundinya</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/results/jeemains2023/srimanth.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 10</span>
                        <h3 class="toppername">Sainadh Srimanth</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- NEET -->
        <div class="col-md-12">
          <div class="resultbg">
            <div class="row jeeresult-flex">
              <div class="col-lg-5 col-xs-12 jeetopper-flex">
                <h3 class="topperhead-2021">
                  Congratulations to Our NEET Toppers 2022
                </h3>
              </div>
              <div class="col-lg-7 col-xs-12">
                <div class="topper">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2022/neet2022/sidharthrao.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 5</span>
                        <h3 class="toppername">Sidharth Rao</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2022/neet2022/arpitnarang.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 7</span>
                        <h3 class="toppername">Arpit Narang</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2022/neet2022/krishnasr.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 8</span>
                        <h3 class="toppername">Krishna S R</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- JEE Main -->
        <div class="col-md-12">
          <div class="resultbg">
            <div class="row jeeresult-flex">
              <div class="col-lg-5 col-xs-12 jeetopper-flex">
                <h3 class="topperhead-2021">
                  Congratulations to Our JEE Main Toppers 2022
                </h3>
              </div>
              <div class="col-lg-7 col-xs-12">
                <div class="topper">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2022/jeemains2022/himavamsi.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 1</span>
                        <h3 class="toppername">Hima Vamsi</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2022/jeemains2022/mrinalgarg.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 5</span>
                        <h3 class="toppername">Mrinal Garg</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2022/jeemains2022/ravikishore.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 6</span>
                        <h3 class="toppername">Ravi Kishore</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- JEE Advanced -->
        <div class="col-md-12">
          <div class="resultbg">
            <div class="row jeeresult-flex">
              <div class="col-lg-5 col-xs-12 jeetopper-flex">
                <h3 class="topperhead-2021">
                  Congratulations to Our JEE Advanced Toppers 2022
                </h3>
              </div>
              <div class="col-lg-7 col-xs-12">
                <div class="topper">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2022/jeeadv2022/johnjoseph.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 1</span>
                        <h3 class="toppername">John Joseph</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-6">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2022/jeeadv2022/himavamsi.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 2</span>
                        <h3 class="toppername">Hima Vamsi</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div class="toppercenter">
                        <figure class="mb-0">
                          <img
                            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/results/2022/jeeadv2022/jalajakshi.webp"
                            alt="" class="jeetopperimg" loading="lazy" />
                        </figure>
                        <span class="rank-title">AIR 3</span>
                        <h3 class="toppername">Jalajakshi</h3>
                        <p class="topperscore">
                          Scored <span class="percentile">100%</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ========================= Our Results ends ========================= -->
  <!-- ========================= Our News and Articles ========================= -->
  <div class="container" *ngIf="!lazynews && lazyresults">
    <div class="row">
      <div class="col-md-12">
        <div class="loadcenter">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- News and Articles sec starts  -->
  <section class="news-area" *ngIf="lazynews">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-xs-12">
          <div class="section-head">
            <h1 class="sec-title mb-40">Our News and Articles</h1>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-xs-12 text-center">
          <div id="newscarouselExampleIndicators" data-ride="carousel" class="carousel slide">
            <ol class="carousel-indicators news-indicators">
              <li data-target="#newscarouselExampleIndicators" data-slide-to="0" class="active"></li>
              <li data-target="#newscarouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#newscarouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#newscarouselExampleIndicators" data-slide-to="3"></li>
              <li data-target="#newscarouselExampleIndicators" data-slide-to="4"></li>
              <li data-target="#newscarouselExampleIndicators" data-slide-to="5"></li>
              <li data-target="#newscarouselExampleIndicators" data-slide-to="6"></li>
              <li data-target="#newscarouselExampleIndicators" data-slide-to="7"></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="newsbox">
                  <div>
                    <figure class="mb-0">
                      <img [src]="news?.wisklub" alt="" class="newsimg"
                        style="background-color: #fff; border-radius: 10px" lazyLoad="news?.wisklub" loading="lazy" />
                    </figure>
                  </div>
                  <div class="news-content">
                    <h3 class="newstitle">
                      Infinity Learn acquires Wizklub for $10 million
                    </h3>
                    <p class="newstext">Backed by Asia’s largest education group Sri Chaitanya, Infinity Learn acquired
                      Wizklub for $10
                      million — a cognitive development start-up that builds skills for children between 6-14 years
                      through High Order
                      Thinking Skills (HOTS) and SmarTech programs. Named as 'Infinity Futurz', this vertical focusses
                      on active
                      learning, critical thinking, innovation, and the ability to use the technology to solve real-world
                      problems.</p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="newsbox">
                  <div>
                    <figure class="mb-0">
                      <img [src]="news?.rohitgyaanchand" alt="" class="newsimg" style="border-radius: 10px;"
                        lazyLoad="news?.rohitgyaanchand" loading="lazy" />
                    </figure>
                  </div>
                  <div class="news-content">
                    <h3 class="newstitle">Rohit Sharma launches the flagship app of Infinity Learn by Sri Chaitanya.
                    </h3>
                    <p class="newstext">Indian Cricket team captain Rohit Sharma launched the Flagship App 'Infinity
                      Learn' on his
                      Instagram Live. Check out more information on this news on the pages like The Global Hues,
                      Adgully, Hindustan
                      Times, Economic Times, etc.</p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="newsbox">
                  <div>
                    <figure class="mb-0">
                      <img [src]="news?.hmedtech" alt="" class="newsimg" style="border-radius: 10px;"
                        lazyLoad="news?.hmedtech" loading="lazy" />
                    </figure>
                  </div>
                  <div class="news-content">
                    <h3 class="newstitle">Infinity Learn gets featured in "10 EdTech Companies Building India As A
                      Global Talent Hub" by
                      The Global Hues.</h3>
                    <p class="newstext">Infinity Learn has become one of India's top 10 EdTech companies, trying to
                      position the nation
                      as a global talent hub. With the knowledge of leveraging emerging technologies, Infinity Learn is
                      a powerful
                      catalyst in boosting the digital capabilities of legacy educational institutions like Sri
                      Chaitanya.</p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="newsbox">
                  <div>
                    <figure class="mb-0">
                      <img [src]="news?.hmprestige" alt="" class="newsimg" style="border-radius: 10px;"
                        lazyLoad="news?.hmprestige" loading="lazy" />
                    </figure>
                  </div>
                  <div class="news-content">
                    <h3 class="newstitle">Infinity Learn bags the Prestigious Brands of Asia awards at the Celebrated
                      Global Business
                      Symposium 2022.</h3>
                    <p class="newstext">Writing history in the star-studded space of ever-evolving technology, Infinity
                      Learn, an
                      outcome-based learning EdTech start-up by Sri Chaitanya Group, has bagged two outstanding awards
                      at Global
                      Business Symposium 2022, including Asia's Prestigious Brands (Rising) in EdTech and Marketing
                      Meister Awards.</p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="newsbox">
                  <div>
                    <figure class="mb-0">
                      <img [src]="news?.news" alt="" class="newsimg" lazyLoad="news?.news" loading="lazy" />
                    </figure>
                  </div>
                  <div class="news-content">
                    <h3 class="newstitle">Startup India Certification</h3>
                    <p class="newstext">
                      Infinity Learn is now officially certified under the
                      Start-up India, a flagship initiative of the Government of
                      India. Start-up India is intended to build a strong
                      ecosystem that is conducive for the growth of start-up
                      businesses, to drive sustainable economic growth and
                      generate large scale employment opportunities.
                    </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="newsbox">
                  <div>
                    <figure class="mb-0">
                      <img [src]="news?.newsrohit" alt="" class="newsimg"
                        style="background-color: #fff; border-radius: 10px" lazyLoad="news?.newsrohit" loading="lazy" />
                    </figure>
                  </div>
                  <div class="news-content">
                    <h3 class="newstitle">
                      InfinityLearn ropes in Rohit Sharma as the Brand
                      Ambassador
                    </h3>
                    <p class="newstext">
                      "I am impressed by the vision and the compelling story of
                      commitment shown by Infinity Learn. I'm glad to be
                      associated with reputed educational institution such as
                      Sri Chaitanya with their digital learning venture Infinity
                      Learn” says Rohit. We look forward to building a strong
                      brand by associating with Rohit.
                    </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="newsbox">
                  <div>
                    <figure class="mb-0">
                      <img [src]="news?.newsteacher" alt="" class="newsimg"
                        style="background-color: #fff; border-radius: 10px" lazyLoad="news?.newsteacher"
                        loading="lazy" />
                    </figure>
                  </div>
                  <div class="news-content">
                    <h3 class="newstitle">
                      Infinity Learn acquires edtech startup Teacherr
                    </h3>
                    <p class="newstext">
                      Teacherr is now part of InfinityLearn. Infinity Learn is
                      on a mission to unlock new ways to learn for all learners.
                      We welcome the Teacherr team on board and look forward to
                      a more robust and driven growth trajectory.
                    </p>
                  </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="newsbox">
                  <div>
                    <figure class="mb-0">
                      <img [src]="news?.newsdontmemorise" alt="" class="newsimg"
                        style="background-color: #fff; border-radius: 10px" lazyLoad="news?.newsdontmemorise"
                        loading="lazy" />
                    </figure>
                  </div>
                  <div class="news-content">
                    <h3 class="newstitle">
                      Infinity Learn acquires Don't Memorise
                    </h3>
                    <p class="newstext">
                      Don't Memorise, a multilingual YouTube content platform is now a part of Infinity Learn. It brings
                      along 2.5 million
                      users to the family. We welcome Don't Memorise and will work to bring more K-10 learning solutions
                      for the learners.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <a href="#newscarouselExampleIndicators" role="button" data-slide="prev"
              class="carousel-control-prev newscarouselprev"><i aria-hidden="true"
                class="fa fa-angle-left news-leftarrow"></i><span class="sr-only">Previous</span></a><a
              href="#newscarouselExampleIndicators" role="button" data-slide="next"
              class="carousel-control-next newscarouselnext"><i aria-hidden="true"
                class="fa fa-angle-right news-rightarrow"></i><span class="sr-only">Next</span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- News and Articles sec ends  -->
  <div class="container" *ngIf="!lazyfooter && lazynews">
    <div class="row">
      <div class="col-md-12">
        <div class="loadcenter">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Doubts area sec starts  -->
  <section class="doubts-area" *ngIf="lazyfooter">
    <div class="container">
      <div class="row align-items-center doubt-flex">
        <div class="col-lg-6 col-md-12 col-xs-12">
          <div class="doubtsimg">
            <figure class="mb-0">
              <img [src]="Doubts" alt="" class="doubt" [lazyLoad]="Doubts" loading="lazy" />
            </figure>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-xs-12">
          <div class="mobdoubt-center">
            <h2 class="playstoretitle">
              Learn Anywhere, Any Time
              <span class="google">Download the Infinity Learn App</span>
            </h2>
            <p class="playstoretext">
              Infinity Learn is the ultimate learning app for classes 6 to 12 and a one-stop solution for all your
              infinite learning needs. Specially designed for the K-12 students - CBSE, IIT JEE, and NEET, the Infinity
              Learn App offers LIVE online classes, learning materials such as videos and flashcards, assessment
              modules, test series, NCERT solutions, instant doubt resolution, and much more.

              Students can attend the free live classes from India’s best teachers, learn at their own pace through
              online videos and take up practice tests for CBSE board exam preparation, IIT JEE preparation, and NEET
              preparation. Our live learning app provides an easy and convenient learning process while reinforcing the
              concepts.
            </p>
            <figure class="mb-0">
              <a *ngIf="isFromBajajFinservAppInApp" href="javascript:void(0)"><img src="/assets/images/doubt.png" alt=""
                  class="playstoreimg" loading="lazy" /></a>
              <a *ngIf="!isFromBajajFinservAppInApp"
                href="https://play.google.com/store/apps/details?id=com.infinitylearn.learn" target="_blank"><img
                  src="/assets/images/doubt.png" alt="" class="playstoreimg" loading="lazy" /></a>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Doubts area sec ends  -->
</div>
<!-- ========================= Footer starts========================= -->
<footer class="footer-area" *ngIf="lazyfooter">
  <app-footer></app-footer>
</footer>
<!-- ========================= Footer ends========================= -->