import { Component, OnInit } from '@angular/core';
import { DatalayerService} from 'src/app/service/datalayer.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  isWebsiteHomepage: boolean = false;
  isFromBajajFinservAppInApp: boolean = false;
  isJeeCrashcourse:boolean = false;
  isPatna:boolean = false;
  constructor(
    private _datalayer:DatalayerService,
    public router: Router,
    ) { }

  ngOnInit(): void {
    if(this.router.url?.split("?")?.[0] == "/") {
      this.isWebsiteHomepage = true;
      this.isFromBajajFinservAppInApp = this.router.url?.includes("utm_source=BajajFinserv") ? true : false;
    }

    this.isJeeCrashcourse = window.location.pathname === '/jee-crashcourse' || window.location.pathname === '/cbse-fullcourse' ? true : false;

    this.isPatna = window.location.pathname === '/patna' ? true : false;
  }

  we_socialmedia(click) {
    this._datalayer.webEngageEvent({
      'event': 'Social Media',
      'Click': click,
      }) 
  };
}
