import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';;
import { NgOtpInputModule } from 'ng-otp-input';
import {SharedModule} from './shared/shared.module';
import {MainpageComponent} from './shared/components/mainpage/mainpage.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { NoCacheHeadersInterceptor } from './Interceptors/NoChacheInterceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CornellComponent } from './cornell/cornell.component';
import { LeadFeedbackComponent } from './lead-feedback/lead-feedback.component';
import { StudentdetailleadComponent } from './studentdetaillead/studentdetaillead.component';
import { LiveclassFeedbackComponent } from './liveclass-feedback/liveclass-feedback.component';

@NgModule({
  declarations: [
    AppComponent,   
    MainpageComponent, CornellComponent, LeadFeedbackComponent, StudentdetailleadComponent, LiveclassFeedbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    SharedModule,
    LazyLoadImageModule,
    NgxJsonLdModule,
    NgxSpinnerModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  //providers: [NewsapiservicesService], This was Before Blog Service
  providers: [DatePipe, {provide: HTTP_INTERCEPTORS, useClass: NoCacheHeadersInterceptor, multi: true}],
  bootstrap: [AppComponent],
})
export class AppModule {}
