import { Component, OnInit } from '@angular/core';
declare let $: any;
declare var jQuery: any;
@Component({
  selector: 'app-whatsappicon',
  templateUrl: './whatsappicon.component.html',
  styleUrls: ['./whatsappicon.component.css']
})
export class WhatsappiconComponent implements OnInit {
  whatsapp:boolean = false;
  buttomfloat:any;
  buttomfloatcbse:any;
  pathName:any;
  pages = ["/", "/neet", "/jee", "/jee-foundation",
            "/neet-foundation", "/cbse/6/full-course", "/cbse/7/full-course", "/cbse/8/full-course", "/cbse/9/full-course",
            "/cbse/10/full-course", "/cbse/11/full-course", "/cbse/12/full-course", "/score","/score/round1", "/jee/repeater",
            "/neet/repeater",];
  constructor() { }
  
  ngOnInit(): void {
    let pathname = window.location.pathname;
    this.pathName = window.location.pathname;
    let href = window.location.href.split("?")[1];
    console.log(pathname,'pathname');
    console.log(href,'href');
    if(href !="utm_source=BajajFinserv&utm_medium=AppInApp"){
      this.whatsapp = true;
    }
    if(!this.pages.includes(pathname)){
      this.buttomfloat = 'buttomfloat';
      this.buttomfloatcbse ="buttomfloatcbse";
    }
  }

  ngAfterViewInit() {
    // (function ($) {
    //   $(document).scroll(function () {
    //     var myID = <HTMLElement>document.getElementById("whatsapp");
    //     var myScrollFunc = function () {
    //       var y = window.scrollY;
    //       if (y >= 300) {
    //         myID.className = "float show"
    //       } else {
    //         myID.className = "float hide"
    //       }
    //     };
    //     if(window.innerWidth <= 481){
    //       window.addEventListener("scroll", myScrollFunc);
    //     }

    //     $("#bookscrollform").click(function () {
    //       $(window).scrollTop(0);
    //     });
    //   })
    // })(jQuery)

  }

}
