export const surgedata = [
    {
        exam: "NCERT Solutions",
        url:'https://infinitylearn.com/surge/study-materials/ncert-solutions/',
        data: [
            {
                class:'12',
                name: 'NCERT Solutions For Class 12',
                url:'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/',
                submenu:[
                    {
                        name: 'NCERT Solutions for Class 12 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/maths/'
                    },
                    {
                        name: 'NCERT Solutions for Class 12 Biology',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/biology/'
                    },
                    {
                        name: 'NCERT Solutions for Class 12 Physics',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/physics/'
                    },
                    {
                        name: 'NCERT Solutions for Class 12 Chemistry',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/chemistry/'
                    },
                ]
            },
            {
                class:'11',
                name: 'NCERT Solutions For Class 11',
                url:'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/',
                submenu:[
                    {
                        name: 'NCERT Solutions for Class 11 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/maths/'
                    },
                    {
                        name: 'NCERT Solutions for Class 11 Biology',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/biology/'
                    },
                    {
                        name: 'NCERT Solutions for Class 11 Physics',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/physics/'
                    },
                    {
                        name: 'NCERT Solutions for Class 11 Chemistry',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/chemistry/'
                    },
                ]
            },
            {
                class:'10',
                name: 'NCERT Solutions For Class 10',
                url:'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/',
                submenu:[
                    {
                        name: 'NCERT Solutions for Class 10 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/maths/'
                    },
                    {
                        name: 'NCERT Solutions for Class 10 English',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/english/'
                    },
                    {
                        name: 'NCERT Solutions for Class 10 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/science/'
                    },
                    {
                        name: 'NCERT Solutions for Class 10 Social Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/social-science/'
                    },
                ]
            },
            {
                class:'9',
                name: 'NCERT Solutions For Class 9',
                url:'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/',
                submenu:[
                    {
                        name: 'NCERT Solutions for Class 9 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/maths/'
                    },
                    {
                        name: 'NCERT Solutions for Class 9 English',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/english/'
                    },
                    {
                        name: 'NCERT Solutions for Class 9 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/science/'
                    },
                    {
                        name: 'NCERT Solutions for Class 9 Social Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/social-science/'
                    },
                ]
            },
            {
                class:'8',
                name: 'NCERT Solutions For Class 8',
                url:'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/',
                submenu:[
                    {
                        name: 'NCERT Solutions for Class 8 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/maths/'
                    },
                    {
                        name: 'NCERT Solutions for Class 8 English',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/english/'
                    },
                    {
                        name: 'NCERT Solutions for Class 8 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/science/'
                    },
                    {
                        name: 'NCERT Solutions for Class 8 Social Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/social-science/'
                    },
                ]
            },
            {
                class:'7',
                name: 'NCERT Solutions For Class 7',
                url:'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/',
                submenu:[
                    {
                        name: 'NCERT Solutions for Class 7 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/maths/'
                    },
                    {
                        name: 'NCERT Solutions for Class 7 English',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/english/'
                    },
                    {
                        name: 'NCERT Solutions for Class 7 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/science/'
                    },
                    {
                        name: 'NCERT Solutions for Class 7 Social Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/social-science/'
                    },
                ]
            },
            {
                class:'6',
                name: 'NCERT Solutions For Class 6',
                url:'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/',
                submenu:[
                    {
                        name: 'NCERT Solutions for Class 6 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/maths/'
                    },
                    {
                        name: 'NCERT Solutions for Class 6 English',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/english/'
                    },
                    {
                        name: 'NCERT Solutions for Class 6 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/science/'
                    },
                    {
                        name: 'NCERT Solutions for Class 6 Social Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/social-science/'
                    },
                ]
            },
            
        ]
    },
    {
        exam: "NCERT Exemplar Solutions",
        url:'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/',
        data: [
            {
                class:'12',
                name: 'NCERT Exemplar Solutions For Class 12',
                url:'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/',
                submenu:[
                    {
                        name: 'NCERT Exemplar Solutions for Class 12 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/maths/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 12 Biology',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/biology/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 12 Physics',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/physics/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 12 Chemistry',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/chemistry/'
                    },
                ]
            },
            {
                class:'11',
                name: 'NCERT Exemplar Solutions For Class 11',
                url:'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/',
                submenu:[
                    {
                        name: 'NCERT Exemplar Solutions for Class 11 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/maths/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 11 Biology',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/biology/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 11 Physics',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/physics/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 11 Chemistry',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/chemistry/'
                    },
                ]
            },
            {
                class:'10',
                name: 'NCERT Exemplar Solutions For Class 10',
                url:'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-10/',
                submenu:[
                    {
                        name: 'NCERT Exemplar Solutions for Class 10 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-10/maths/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 10 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-10/science/'
                    },
                ]
            },
            {
                class:'9',
                name: 'NCERT Exemplar Solutions For Class 9',
                url:'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-9/',
                submenu:[
                    {
                        name: 'NCERT Exemplar Solutions for Class 9 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-9/maths/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 9 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-9/science/'
                    },
                ]
            },
            {
                class:'8',
                name: 'NCERT Exemplar Solutions For Class 8',
                url:'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-8/',
                submenu:[
                    {
                        name: 'NCERT Exemplar Solutions for Class 8 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-8/maths/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 8 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-8/science/'
                    },
                ]
            },
            {
                class:'7',
                name: 'NCERT Exemplar Solutions For Class 7',
                url:'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-7/',
                submenu:[
                    {
                        name: 'NCERT Exemplar Solutions for Class 7 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-7/maths/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 7 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-7/science/'
                    },
                ]
            },
            {
                class:'6',
                name: 'NCERT Exemplar Solutions For Class 6',
                url:'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-6/',
                submenu:[
                    {
                        name: 'NCERT Exemplar Solutions for Class 6 Maths',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-6/maths/'
                    },
                    {
                        name: 'NCERT Exemplar Solutions for Class 6 Science',
                        url: 'https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-6/science/'
                    },
                ]
            },
        ]
    },
    {
        exam: "JEE Main",
        url:'',
        data: [
            {
                class:'12',
                name: 'Previous Year Question Paper',
                url:'/jee-main-question-paper-2023',
                submenu:[]
            },
            
        ]
    },
    {
        exam: "Rank Predictor",
        url:'',
        data: [
            {
                class:'12',
                name: 'NEET',
                url:'/rank-predictor-neet',
                submenu:[]
            },
            {
                class:'12',
                name: 'JEE',
                url:'/rank-predictor-jee',
                submenu:[]
            },
        ]
    }   
]