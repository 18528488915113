import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { unifiedWeb } from 'src/app/service/app.constant';
import {surgedata} from 'src/app/service/surge-data';
import { Grades } from '../header/header.component';
declare let $: any;
@Component({
  selector: 'app-header-mob',
  templateUrl: './header-mob.component.html',
  styleUrls: ['./header-mob.component.css'],
})
export class HeaderMobComponent implements OnInit {
  studentportal = environment.studentportal;
  utm:any = [];
  Grades = Grades;
  constructor(
    public route: ActivatedRoute,
    private router: Router) {}
  public unifiedWeb  = unifiedWeb;
  surgeData = surgedata;
  ngOnInit(): void {
    if(this.router.url.split('?')[1] != undefined) {
      this.utm = '?'+this.router.url.split('?')[1]+'&page='+this.router.url.split('?')[0].slice(1);
    } else if(this.router.url != '/') {
      this.utm = '?page='+this.router.url.split('?')[0].slice(1);
    } else if(this.router.url == '/'){
      this.utm = '?page=signup'
    }
  }

  naviagteToGrade(grade: any) {
    window.open('/cbse/'+grade+'/full-course','_self');
  }

  // naviagteToCrash(grade: any) {
  //   window.open('/cbse/'+grade+'/crash-course','_self');
  // }
  
  naviagteToCrash(grade: any) {
    window.open('/cbse/'+grade,'_self');
  }
  naviagteFuondation(course){
    window.open('foundation/'+course,'_self');
  }
  navigateTosignupPage(redirectTo){
    if(redirectTo == 'signin' && this.utm =="?page=signup"){
      window.location.href =`${this.studentportal}signin?page=signin`;
    }else{
      window.location.href =this.studentportal+'signin'+this.utm;
    }
    if(sessionStorage.getItem("isFromBajajFinservAppInApp") === "true" && !this.utm?.includes("utm_source=BajajFinserv")) {
      this.utm += "&utm_source=BajajFinserv&utm_medium=AppInApp"
    }
  }

  showExams(a){
    $('#exams' + a).toggleClass('show');
  }
  showClass(b){
    $('#urls' + b).toggleClass('show');
  }
  openUrl(url){
    window.open(url);
  }

}
