export const environment = {
  production: true,
  isSelfLearnShow: true,
  isMigrationShow: false,
  name: "preprod", //case senstive dont change
  studentportal: "https://student.devinfinitylearn.in/",
  // studentportal: "https://studentcartflowpreprod.devinfinitylearn.in/",
  routingDomain:".devinfinitylearn.in",
  unifiedweb:'https://unifiedpreprod.devinfinitylearn.in/',
  omsapi: "https://omsapi.devinfinitylearn.in",
  assessmentapi: "https://newasmapi.devinfinitylearn.in",
  liveclassapi: "https://livepreprodapi.devinfinitylearn.in",
  webapi: "https://api.devinfinitylearn.in",
  scat: "https://srichaitanyascore.devinfinitylearn.in",
  SELF_LEARN_API: 'https://selflearnapi.devinfinitylearn.in/',
  uafapi: 'https://myactivity.devinfinitylearn.in/api/',
  razorpay_key: "rzp_test_cbwfDx2vukD9jT",
  firebaseConfig : {
    apiKey: "AIzaSyAKIzu_pegviRDYGhVR7xqX9F2pY4N0M14",
    authDomain: "unified-infinity-learn.firebaseapp.com",
    databaseURL: "https://unified-infinity-learn-default-rtdb.firebaseio.com",
    projectId: "unified-infinity-learn",
    storageBucket: "unified-infinity-learn.appspot.com",
    messagingSenderId: "668051385949",
    appId: "1:668051385949:web:820adef20962928ddfe7b4",
    measurementId: "G-NVQRR77Z5K"
  },
  Bajaj: {
    showBajaj: true,
    min_amount: 1,
    max_amount: 2,
    Durations: [
      { tenor: 3, schemeId: "81526" },
      { tenor: 6, schemeId: "81527" },
    ],
  },
};