<ul>
  <li class="nav-item has-submenu">
    <a href="" class="nav-link" data-toggle="collapse" data-target="#studymeterial" aria-expanded="false" aria-controls="collapseExample">
      STUDY MATERIAL <i class="fa fa-chevron-down"></i></a>
    <ul class="submenu collapse"  id="studymeterial">
      <li class="nav-item has-submenu" *ngFor="let exams of surgeData;let a = index">
        <a *ngIf="exams?.url != '/rank-predictor-jee'" href="" class="nav-link ncertpl"  data-toggle="collapse" (click)="showExams(a)" data-target="#exams{{a}}" aria-expanded="false" aria-controls="collapseExample">{{exams?.exam}} <i class="fa fa-chevron-down"></i></a>
        <a *ngIf="exams?.url === '/rank-predictor-jee'" href="{{exams?.url}}" class="nav-link ncertpl">{{exams?.exam}}</a>
        <ul class="submenu collapse" id="exams{{a}}" *ngIf="exams.data.length>0">
          <li class="nav-item has-submenu" *ngFor="let ncert of exams?.data;let b = index">
            <a *ngIf="ncert?.url != '/jee-main-question-paper-2023'" class="nav-link ncertsubpl" (click)="openUrl(ncert?.url)" data-toggle="collapse" (click)="showClass(b)" data-target="#urlsn{{b}}" aria-expanded="false" aria-controls="collapseExample">{{ncert?.name}} <i *ngIf="ncert.submenu.length>0" class="fa fa-chevron-down"></i></a>
            <a *ngIf="ncert?.url === '/jee-main-question-paper-2023'" class="nav-link ncertsubpl" href="{{ncert?.url}}">{{ncert?.name}}</a>
            <ul class="submenu collapse" id="urls{{b}}" *ngIf="ncert.submenu.length>0">
              <li class="nav-item has-submenu" *ngFor="let url of ncert?.submenu">
                <a class="nav-link ncertsuperpl" href="{{url?.url}}" target="_blank">{{url?.name}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </li>
  <li><a href="/">Home</a></li>
  <!-- <li><a href="/jee">JEE</a></li>
  <li><a href="/neet">NEET</a></li> -->
  <li class="nav-item has-submenu">
    <a href="" class="nav-link" data-toggle="collapse" data-target="#navbarcourses" aria-expanded="false" aria-controls="collapseExample">
      COURSES <i class="fa fa-chevron-down"></i></a>
    <ul class="submenu collapse" id="navbarcourses">
      <li class="nav-item has-submenu">
        <a class="nav-link" href="/jee">
          JEE
          <span><a class="mob_span_nopad" data-toggle="collapse" data-target="#jee" aria-expanded="false"
              aria-controls="collapseExample"><i class="fa fa-chevron-down"></i></a></span>
        </a>
          
          <ul class="submenu collapse" id="jee">
            <li class="nav-item has-submenu">
              <a class="nav-link" href="jee-13">Grade 13</a>
            </li>
            <li class="nav-item has-submenu">
              <a class="nav-link" href="jee-12">Grade 12</a>
            </li>
            <li class="nav-item has-submenu">
              <a class="nav-link" href="jee-11">Grade 11</a>
            </li>
          </ul>
      </li>
      <!-- <li class="nav-item has-submenu">
        <a class="nav-link" href="/jee-advanced">
          JEE Advanced</a>
      </li> -->
      <li class="nav-item has-submenu">
        <a class="nav-link" href="/neet">
          NEET
          <span><a class="mob_span_nopad" data-toggle="collapse" data-target="#neet" aria-expanded="false"
              aria-controls="collapseExample"><i class="fa fa-chevron-down"></i></a></span>
        </a>
          <ul class="submenu collapse" id="neet">
            <li class="nav-item has-submenu">
              <a class="nav-link" href="/neet-13">Grade 13</a>
            </li>
            <li class="nav-item has-submenu">
              <a class="nav-link" href="neet-12">Grade 12</a>
            </li>
            <li class="nav-item has-submenu">
              <a class="nav-link" href="neet-11">Grade 11</a>
            </li>
          </ul>
      </li>
      <li class="nav-item has-submenu">
        <a class="nav-link" href="/cuet">
          CUET</a>
      </li>
      <li class="nav-item has-submenu">
        <a href="" class="nav-link" data-toggle="collapse" data-target="#foundation" aria-expanded="false" aria-controls="collapseExample">
          FOUNDATION <i class="fa fa-chevron-down"></i></a>
        <ul class="submenu collapse" id="foundation">
          <li class="nav-item has-submenu">
            <a class="nav-link" (click)="naviagteFuondation('8')">Grade 8</a>
          </li>
          <li class="nav-item has-submenu">
            <a class="nav-link" (click)="naviagteFuondation('9')">Grade 9</a>
          </li>
          <li class="nav-item has-submenu">
            <a class="nav-link" (click)="naviagteFuondation('9')">Grade 10</a>
          </li>
        </ul>
      </li>
      <!-- <li class="nav-item has-submenu">
        <a href="" class="nav-link" data-toggle="collapse" data-target="#cbseGrade" aria-expanded="false" aria-controls="collapseExample">
          CBSE <i class="fa fa-chevron-down"></i></a>
        <ul class="submenu collapse" id="cbseGrade">
          <li class="nav-item has-submenu" *ngFor="let grade of Grades">
            <a class="nav-link" (click)="naviagteToCrash(grade.grade)">
              Grade {{grade.grade}}</a>
          </li>
        </ul>
      </li> -->
    </ul>
  </li>
  <li><a href="https://schools.infinitylearn.com/">SCHOOLS</a></li>

  <li><a href="/score">SCORE</a></li>
  <li><a (click)="navigateTosignupPage('signin')" href="javascript:void(0)">Sign in</a></li>
  <li>
    <a href="{{studentportal}}signup{{utm}}" class="mobsignup-btn">Sign Up</a>
  </li>

</ul>