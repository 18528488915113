import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private _http: HttpClient) { }

  faculty_apiurl = 'https://laravelcms.devinfinitylearn.in/api/faculty';
  liveCalssApi = environment?.liveclassapi;
  omsApiurl = environment?.omsapi;
  assessment_api = environment.assessmentapi;
  liveclasses_apiurlV2 = environment.liveclassapi; 
  errorHandler(error: HttpErrorResponse) {
    return new Observable((observer: Observer<any>) => {
      observer.error(error);
    });
  }


  faculty_api(): Observable<any> {
    return this._http.get(this.faculty_apiurl);
  }

  testimonial_apiurl = 'https://laravelcms.devinfinitylearn.in/api/testimonial';

  testimonial_api(): Observable<any> {
    return this._http.get(this.testimonial_apiurl);
  }

  cbse_liveclass_apiurl = environment.liveclassapi + "/api/v1/eclasses?type=masterclasses&page=1&limit=6";
  liveclasses_apiurl = environment.liveclassapi + '/api/v1/eclasses?type=masterclasses&page=1&limit=6';

  getCurrentServerTime() {
    const headers = new HttpHeaders()
      .set('accept', '*/*')
    return this._http.get(this.liveclasses_apiurl, { headers: headers });
  }

  jee_liveclasses_api(grade): Observable<any> {
    const headers = new HttpHeaders()
      .set('accept', '*/*')
      .set('ot-grade', `${grade}`)
      .set('ot-exams', '["JEE Main","JEE Advanced"]');
    return this._http.get(this.liveclasses_apiurl, { headers: headers });
  }

  neet_liveclasses_api(grade): Observable<any> {
    const headers = new HttpHeaders()
      .set('accept', '*/*')
      .set('ot-grade', `${grade}`)
      .set('ot-exams', '["NEET"]');
    return this._http.get(this.liveclasses_apiurl, { headers: headers });
  }

  cbse_liveclasses_api(grade): Observable<any> {
    const headers = new HttpHeaders()
      .set('accept', '*/*')
      .set('ot-grade', grade)
      .set('ot-student', '0')
      .set('ot-exams', '["CBSE"]');
    return this._http.get(this.cbse_liveclass_apiurl, { headers: headers });
  }

  liveclasses_apiV2(grade,exams,subjects){
    let url = this.liveclasses_apiurlV2 + '/api/v1/eclasses?type=masterclasses';
    const headers = new HttpHeaders()
    .set('accept', '*/*')
    .set('ot-grade', `${grade}`)
    .set('ot-exams', exams)
    .set('subject', subjects);
  return this._http.get(url, { headers: headers });
  }

  pastClasses_api(grade,exams,subjects){
    let url = this.liveclasses_apiurlV2 + '/api/v1/eclasses/student/all?filter=past&type=masterclasses&page=1&limit=6&days=all';
    const headers = new HttpHeaders()
    .set('accept', '*/*')
    .set('ot-grade', `${grade}`)
    .set('ot-exams', exams)
    .set('subject', subjects);
  return this._http.get(url, { headers: headers });
  }

  getClassDetails(classId: any) {
    return this._http.get(`${this.liveclasses_apiurlV2}/api/v1/eclasses/${classId}`);
  }
  classRegistre(id: any, userId: any) {
    let url = this.liveclasses_apiurlV2 + '/api/v1/';
    let body = {
      student: userId,
    };
    return this._http.post(`${url}eclasses/${id}/register`, body);
  }

  //Subscription Starts Here
  subscription_apiurl = environment.omsapi + '/open/features/getOpenPackagesAndSubscriptions';

  subscription_api(exam, grade, year, producttype?): Observable<any> {
    let producttypes = producttype ? producttype : 'assessment,liveclasses,recordedcourse,hardware,selflearn';
    const headers = new HttpHeaders().set('accept', '*/*');
    return this._http.get(
      this.subscription_apiurl +
      `?examName=${exam}&grade=${grade}&producttype=${producttypes}&year=${year}`,
      { headers: headers }
    );
  }

  subscription_api_v2(exam, grade, year, pageUrl?, producttype?): Observable<any> {
    let producttypes = producttype ? producttype : 'assessment,liveclasses,recordedcourse,hardware,selflearn';
    const headers = new HttpHeaders().set('accept', '*/*');
    return this._http.get(
      this.subscription_apiurl +
      `?examName=${exam}&grade=${grade}&producttype=${producttypes}&year=${year}&pageUrl=${pageUrl}`,
      { headers: headers }
    );
  }

  assessment_api_url = environment.assessmentapi + "/score/";
  getTimeSlots(token: any, body: any) {
    const _headers = {
      'accesstoken': token ? token : localStorage.getItem('token'),
      "Authorization": "Bearer "+localStorage.getItem('token'),
      'x-tenant': "infinitylearn"
    };
    return this._http.post<any>(this.assessment_api_url + "exam/timeslots", body, { headers: _headers });
  }
  registerExamSchedule(body: any) {
    const url = this.assessment_api_url + `registerstudentscheduledexam `;
    let headers: any = {
      'accesstoken': localStorage.getItem('token'),
      "Authorization": "Bearer "+localStorage.getItem('token'),
      "x-tenant": "infinitylearn"
    };
    return this._http.post<any>(url, body, { headers: headers });
  }
  getScoreTimeSlots(token: any, body: any) {
    const _headers = {
      'accesstoken': token ? token : localStorage.getItem('token'),
      "Authorization": "Bearer "+localStorage.getItem('token'),
      'x-tenant': "infinitylearn"
    };
    return this._http.post<any>(this.assessment_api_url + "getScoreTimeslots", body, { headers: _headers });
  }
  getExamSchedule(token: any) {
    const _headers = {
      'accesstoken': token ? token : localStorage.getItem('token'),
      "Authorization": "Bearer "+localStorage.getItem('token'),
      'x-tenant': "infinitylearn",
      'Content-Type': 'application/json',
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'SAMEORIGIN'
    };
    return this._http.get<any>(this.assessment_api_url + "getScoreSchedules", { headers: _headers })
  }
  getStudentScoreExam(body: any) {
    const url = this.assessment_api_url + `getStudentScoreExam `;
    let headers: any = {
      'accesstoken': localStorage.getItem('token'),
      "Authorization": "Bearer "+localStorage.getItem('token'),
      "x-tenant": "infinitylearn"
    };
    return this._http.post<any>(url, body, { headers: headers });
  }

  getNotCompletedTest(body: any) {
    const url = this.assessment_api + `/exam/getCardTests`;
    let headers: any = {
      'accesstoken': localStorage.getItem('token'),
      "Authorization": "Bearer "+localStorage.getItem('token'),
      "x-tenant": "infinitylearn"
    };
    return this._http.post<any>(url, body, { headers: headers });
  }

  registerExamScheduleAits(body: any) {
    const url = this.assessment_api + `/registrations/registerstudentscheduledexam`;
    let headers: any = {
      'accesstoken': localStorage.getItem('token'),
      "Authorization": "Bearer "+localStorage.getItem('token'),
      "x-tenant": "infinitylearn"
    };
    return this._http.post<any>(url, body, { headers: headers });
  }
  //Micro courses Subscription
  micro_courses_sub_api(grade, year): Observable<any> {
    const headers = new HttpHeaders().set('accept', '*/*');
    return this._http.get(
      this.subscription_apiurl +
      `?examName=CBSE&grade=${grade}&pageUrl=http://infinitylearn.com/math-science-courses&producttype=selflearn&year=${year}`,
      { headers: headers }
    );
  }

  neet_micro_courses_sub_api(): Observable<any> {
    const headers = new HttpHeaders().set('accept', '*/*');
    return this._http.get(
      this.subscription_apiurl +
      `?examName=NEET&grade=13&pageUrl=http://infinitylearn.com/rap-neet&producttype=selflearn,assessment&year=2022`,
      { headers: headers }
    );
  }
  //Score Subscription Offline
  score_sub_api_offline(exam, grade, year, pageUrl): Observable<any> {
    const headers = new HttpHeaders().set('accept', '*/*');
    return this._http.get(
      this.subscription_apiurl +
      `?examName=${exam}&grade=${grade}&producttype=assessment%2Cliveclasses&pageUrl=${pageUrl}&year=${year}`,
      { headers: headers }
    );
  }

  fetchBatchDetails(id) {
    return this._http.get(this.liveCalssApi + '/api/v1/courses/batches/' + id).pipe(catchError(this.errorHandler))
  }

  getAllCartItems(param) {
    let token = localStorage.getItem('token')
    const headers = new HttpHeaders()
      .set('accept', '*/*')
      .set('Authorization', `Bearer ${token}`)
      .set('X-Tenant', 'infinitylearn')
      .set('Content-Type', 'application/json')
      .set('X-Content-Type-Options', 'nosniff')
      .set('X-Frame-Options', 'DENY');

    return this._http.get<any>(this.omsApiurl + `/api/cart/getAllCartItemByUserId?grade=${param?.grade}&userId=${param?.userId}`, { headers: headers }).pipe(catchError(this.errorHandler))
  }

  saveCart(body) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders()
      .set('accept', '*/*')
      .set('Authorization', `Bearer ${token}`)
      .set('X-Tenant', 'infinitylearn')
      .set('Content-Type', 'application/json')
      .set('X-Content-Type-Options', 'nosniff')
      .set('X-Frame-Options', 'SAMEORIGIN');
    return this._http.post(this.omsApiurl + '/api/cart/save', body, { headers: headers }).pipe(catchError(this.errorHandler))
  }

  deleteFromCart(cartid) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders()
      .set('accept', '*/*')
      .set('Authorization', `Bearer ${token}`)
      .set('X-Tenant', 'infinitylearn')
      .set('Content-Type', 'application/json')
      .set('X-Content-Type-Options', 'nosniff')
      .set('X-Frame-Options', 'DENY');
    let url = `${this.omsApiurl}/api/cart/delete?cartId=${cartid}`
    return this._http.delete(url, { headers: headers });
  }

  calulateDiscountForCartItemUsingGET(param:any) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`)
      .set('Ot-Tenant', 'infinitylearn')
      .set('X-Tenant', 'infinitylearn')
      .set('X-Platform', 'web') 
      .set('X-DeviceId', "127.0.0.1")
      let url = `${this.omsApiurl}/api/discountcodes/calulateDiscountForCartItem?code=${param?.code}&grade=${param?.grade}&userId=${param?.userId}`
    return this._http.get(url, { 'headers': headers }).pipe(catchError(this.errorHandler))
  }

  createCartOrder(body){
    let token = localStorage.getItem('token');
    let headers = {
      'Authorization': `Bearer ${token}`,
      'accesstoken': localStorage.getItem('token'),
      'Content-Type': 'application/json',
      'X-Content-Type-Options': 'nosniff',
      'X-Frame-Options': 'SAMEORIGIN'
    };
    body.userPlatform = body?.portal||'website';
    let url = this.omsApiurl+`/api/userorders/createUserCartOrder`;
    return this._http.post(url,body,{ headers: headers}).pipe(catchError(this.errorHandler));
  }

  getExistPackagesOrderDetail(packId,userId){
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`)
      .set('Ot-Tenant', 'infinitylearn')
      .set('X-Tenant', 'infinitylearn')
      let url = `${this.omsApiurl}/api/packages/getExistPackagesOrderDetail?packageId=${packId}&user_id=${userId}`
    return this._http.get(url, { 'headers': headers }).pipe(catchError(this.errorHandler))

  }
}
