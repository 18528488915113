<div class="page-container">

<div class="row">
    <header class="header">
    <nav class="navbar navbar-expand-lg home-navbar">
       <div class="hmnavflex">
          <div>
             <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" alt="Logo" class="headerlogo" /></a>
          </div>
          <app-header-signup></app-header-signup>
       </div>
       <!-- <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" alt="Logo" class="headerlogo" /></a> -->
       <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <app-header></app-header>
       </div>
       <div id="mySidenav" class="sidenav">
          <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
          <app-header-mob></app-header-mob>
       </div>
       <span class="leftnav" onclick="openNav()">&#9776; </span>
    </nav>
  </header>
</div>  
<div class="feedback-container"  >
    <ng-container *ngIf="!feedbackdone">
<div class="d-flex-column">
        <h1 >Recorded Class</h1>
        <button class="submit-btn" (click)="feedBack('recordedclass')"> Submit</button>
    </div>
    <div class="d-flex-column">
        <h1 >Test Series</h1>
        <button class="submit-btn"(click)="feedBack('testseries')"> Submit</button>
    </div>
    </ng-container>
    <ng-container *ngIf="feedbackdone">
        <div class="d-flex-column">
            <h1 >Thanks for your feedBack!</h1> 
        </div>
    </ng-container>
</div>






<div class="modal" id="showEmiPlans" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered " role="document">
        <div class="modal-content">
            <div class="festnspopup">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="feedBack('popupclose')"><span
                    aria-hidden="true" class="bg-close">&times;</span></button>
            </div>
            <div class="modal-body ">
                <div class="row">
                <!-- <h2 class="help-detail">Help us with your Details</h2>    
                <h4 class="yellow-tagline">To personalise the content</h4> -->
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form>
                            <div class="form-group">
                              <label for="exampleFormControlInput1">Name</label>
                              <input type="text" class="form-control" name="name" [(ngModel)]="feedbackBody.name" >
                            </div>
                            <div class="form-group">
                              <label for="exampleFormControlSelect1">Grade</label>
                              <select class="form-control" name="grade" [(ngModel)]="feedbackBody.grade">
                                    <option *ngFor="let grade of [1,2,3,4,5,6,7,8,9,10,11,12,13]" [value]="grade"> {{grade}}</option>
                              </select>
                            </div>
                            <div class="form-group">
                              <label for="exampleFormControlSelect2">Target Exam</label>
                              <select   class="form-control" id="exampleFormControlSelect2" [(ngModel)]="feedbackBody.targetexam">
                                <option>NEET</option>
                                <option>JEE Main</option>
                                <option>JEE Advanced</option>
                                <option>Foundation</option>
                                <option>CBSE</option>
                                <option>CUET</option>

                              </select>
                            </div> 
                          </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="feedBack('popupsubmit')" data-dismiss="modal" >Save</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="feedBack('popupclose')">Close</button>
              </div>
        </div>
    </div>
</div>
</div>