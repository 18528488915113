 <!-- ========================= header start ========================= -->
 <div class="container" style="height: 100vh;">
    <div class="row">

    <header class="header">
    <nav class="navbar navbar-expand-lg home-navbar">
       <div class="hmnavflex">
          <div>
             <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" alt="Logo" class="headerlogo" /></a>
          </div>
          <app-header-signup></app-header-signup>
       </div>
       <!-- <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" alt="Logo" class="headerlogo" /></a> -->
       <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <app-header></app-header>
       </div>
       <div id="mySidenav" class="sidenav">
          <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
          <app-header-mob></app-header-mob>
       </div>
       <span class="leftnav" onclick="openNav()">&#9776; </span>
    </nav>
  </header>
 

    </div>
    <div class="cornel-heading" >
        <h1>{{cornellUtility.totalData?.topic}}</h1>
        <div class="row flex-unwrap w-100 justify-content-between">
            <div class="d-flex-column">
                <div>Date:{{cornellUtility?.date | date:'dd-MM-YYYY'}}  </div>
                <div>Time: {{cornellUtility?.date | date:'hh:mm a'}} </div>
            </div>
            <div class="d-flex-column">
                <div>Faculty Name:{{cornellUtility.totalData?.teacher}}  </div>
                <div>Subject: Botany</div>
            </div>
        </div>
    </div>
    <div class="description-container">
        <div class="col-lg-4 col-md-6 col-sm-12 cues-container">
            <h2>Cues</h2>
            <ul class="average-velocity-is-defined-as">
                <li class="si-unit-of-average-velocity-is" *ngFor="let cue of cornellUtility.totalData?.Cues">
                  <span
                    >{{cue}}</span
                  >
                </li>
              </ul>
        </div>
        <div class="col-lg-8 col-md-6 col-sm-12  notes-container">
            <h2>Notes</h2>
            <ul class="average-velocity-is-defined-as">
                <li class="si-unit-of-average-velocity-is" *ngFor="let note of cornellUtility.totalData?.notes">
                  <span
                    > {{note}}
                  </span>
                </li>

              </ul>
        </div>
    </div>
    <div class="summary-container">
        <h2>Summary</h2>
        <div>
            {{cornellUtility.totalData?.summary}}
        </div>
    </div>
 </div>