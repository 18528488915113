import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainpageComponent } from './shared/components/mainpage/mainpage.component';
import { NotfoundComponent } from './shared/components/notfound/notfound.component';
import { CornellComponent } from './cornell/cornell.component';
import { LeadFeedbackComponent } from './lead-feedback/lead-feedback.component';
import { LiveclassFeedbackComponent } from './liveclass-feedback/liveclass-feedback.component';
const routes: Routes = [
  { path: '', component: MainpageComponent },
  {
    path: '', loadChildren: () => import(
        './rohit-chart/rohit-chart.module'
      ).then((m) => m.RohitChartModule),
  },
  {
    path: '', loadChildren: () => import(
        './referral/referaal.module'
      ).then((m) => m.ReferralModule),
  },
  {
    path: '', loadChildren: () => import(
        './liveclasses/liveclasses.module'
      ).then((m) => m.LiveclassesModule),
  },
  {
    path: '', loadChildren: () => import(
        './freetests/freetests.module'
      ).then((m) => m.FreetestsModule),
  },
  {
    path: '', loadChildren: () => import(
        './sessions/sessions.module'
      ).then((m) => m.SessionsModule),
  },
  {
    path: '', loadChildren: () => import(
        './autopayments/autopayments.module'
      ).then((m) => m.AutopaymentsModule),
  },
  {
    path: '', loadChildren: () => import(
        './international/international.module'
      ).then((m) => m.InternationalModule),
  },
  {
    path: '', loadChildren: () => import(
        './marketingfoure/marketingfoure.module'
      ).then((m) => m.MarketingfoureModule),
  },
  {
    path: '', loadChildren: () => import(
        './product/product.module'
      ).then((m) => m.ProductModule),
  },
  {
    path: 'aits', loadChildren: () => import(
        './marketing2/marketing2.module'
      ).then((m) => m.Marketing2Module),
  },
  {
    path: '', loadChildren: () => import(
        './micro-courses/micro-courses.module'
      ).then((m) => m.MicroCoursesModule),
  },
  {
    path: '', loadChildren: () => import(
        './knowledgehub/knowledgehub.module'
      ).then((m) => m.KnowledgehubModule),
  },
  {
    path: '', loadChildren: () => import(
        './marketing/marketing.module'
      ).then((m) => m.MarketingModule),
  },
  {
    path: '', loadChildren: () => import(
        './marketingthree/marketingthree.module'
      ).then((m) => m.MarketingthreeModule),
  },
  {
    path: '', loadChildren: () => import(
        './shared/shared.module'
      ).then((m) => m.SharedModule),
  },
  {
    path: '', loadChildren: () => import(
        './careers/careers.module'
      ).then((m) => m.CareersModule),
  },
  {
    path: '', loadChildren: () => import(
        './infinity/infinity.module'
      ).then((m) => m.InfinityModule),
  },
  {
    path: '', loadChildren: () => import(
        './content-page/content-page.module'
      ).then((m) => m.ContentPageModule),
  },
  {
    path: '', loadChildren: () => import(
        './srichaitanya/srichaitanya.module'
      ).then((m) => m.SrichaitanyaModule),
  },
  {
    path: '', loadChildren: () => import(
        './results/results.module'
      ).then((m) => m.ResultsModule),
  },
  {
    path: 'cornell-ai',  
    component:CornellComponent
  },
  {
    path: 'leadfeedback',  
    component:LeadFeedbackComponent
  },
  {
    path: 'liveclassFeedback',  
    component:LiveclassFeedbackComponent
  },


  { path: '**', component: NotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
