<div style="display: flex;flex-direction: column;height: 100vh;justify-content: center;align-items: center;">

<div class="row">
    <header class="header">
    <nav class="navbar navbar-expand-lg home-navbar">
       <div class="hmnavflex">
          <div>
             <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" alt="Logo" class="headerlogo" /></a>
          </div>
          <app-header-signup></app-header-signup>
       </div>
       <!-- <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" alt="Logo" class="headerlogo" /></a> -->
       <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <app-header></app-header>
       </div>
       <div id="mySidenav" class="sidenav">
          <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
          <app-header-mob></app-header-mob>
       </div>
       <span class="leftnav" onclick="openNav()">&#9776; </span>
    </nav>
  </header>
</div>  
<div class ="videos-conainer">
    <h1>Get Notes</h1>
    <table class="table">
        <thead>
          <tr>
            <th>Topic</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Synthetic Fibre</td>
            <td><a href="https://staging.devinfinitylearn.in/cornell-ai?url=https://dev-live-classes.s3.ap-south-1.amazonaws.com/class-media/643e686cdf5ec003bd62b26e/bc760a759f4b37404aaf2fa896ae1a64_62682e43d4b2db035f5eae13_0+%28online-video-cutter.com%29.mp4">View Notes</a></td>
          </tr>
          <tr>
            <td>Getting to Know Plants 1</td>
            <td><a href="https://staging.devinfinitylearn.in/cornell-ai?url=https://dev-live-classes.s3.ap-south-1.amazonaws.com/class-media/643e686cdf5ec003bd62b26e/2b9eb1d53d48101247de7e9ae1a56491-62eeb1cf7c75cc036b71deea-0_InCkTa6e.mp4">View Notes</a></td>
          </tr>
        </tbody>
      </table>
</div>
</div>
