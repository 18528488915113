<header class="header">
    <nav class="navbar navbar-expand-lg home-navbar">
      <div class="hmnavflex">
        <div>
            <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" alt="Logo" class="headerlogo"/></a>
        </div>
        <app-header-signup></app-header-signup>
    </div>
      <!-- <a class="navbar-brand" href="#"><img src="/assets/images/logo.png" alt="Logo" class="headerlogo" /></a> -->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <app-header></app-header>
      </div>
      <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
        <app-header-mob></app-header-mob>
      </div>
      <span class="leftnav" onclick="openNav()">&#9776; </span>
    </nav>
</header>
  <!-- ========================= header end ========================= -->
  
  <!-- ========================= Alert ========================= -->
  <!-- <app-alert></app-alert> -->
    <div class="neetJeepage">
        <!-- ========================= Hero area ========================= -->
        <section class="ns-score-onbillingarea">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12 bct-demovideo">
                        <div class="success-center">
                            <figure>
                                <img src="/assets/images/jeecrashcourse/checksuccess.png" alt="" class="scoffcheckimg">
                            </figure>
                            <h1 class="jregname">Hi, <span>{{fullName}}</span></h1>
                            <p class="regthnk-text">Thank you for showing interest in our courses</p>
                            <p class="regthnk-text">Our academic counsellors look forward to serving you soon!</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- ========================= Hero area end========================= -->
        </div>
    
<footer class="footer-area">
  <app-footer></app-footer>
</footer>