import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-lead-feedback',
  templateUrl: './lead-feedback.component.html',
  styleUrls: ['./lead-feedback.component.css']
})
export class LeadFeedbackComponent implements OnInit {
public feedbackBody :any={
    "name": "",
    "phone": "",
    "grade": "",
    "targetexam": "",
    "activity": ""
}
feedbackdone:boolean = false;
  constructor(public http:HttpClient, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.feedbackBody.phone = this.route.snapshot.queryParamMap.get('phone')||'';
    this.feedbackBody.name = this.route.snapshot.queryParamMap.get('name')||'';
    this.feedBack('webpage')
    $('#showEmiPlans').modal({ backdrop: 'static',
    keyboard: false},'show');
    
  }

  feedBack(type){
    this.feedbackBody.activity = type;
   let url ='https://newasmstageapi.devinfinitylearn.in/whatsapp/modifyLeadscore'
  // return;
   this.http.post(url,this.feedbackBody).subscribe((resp:any)=>{
      if(this.feedbackBody.activity == 'recordedclass' || this.feedbackBody.activity =='testseries'){
        this.feedbackdone = true
      }
   })
  }
}
